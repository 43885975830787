<template>
    <div>
      
 <b-container  class="p-0" >
     
     
    <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 

        <img :src="$store.state.backendUrl+post.image" alt="">
      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 
       <div v-html="post.description" class="text-right"></div>

      </b-col>
    </b-row>

    <b-row class="mt-5">
      <b-col cols="12" lg="12" xl="12" > 
               
             <b-nav class="shareBTNS" >
                 <b-nav-item> شارك : </b-nav-item>
            <b-nav-item  :href="'https://www.facebook.com/sharer/sharer.php?u='+$store.state.baseUrl+'ar/about'" target="_blank"><b-icon icon="facebook" scale="1" shift-v="1"  aria-hidden="true"></b-icon></b-nav-item>
            <b-nav-item  :href="'https://twitter.com/intent/tweet?text='+post.name+' '+$store.state.baseUrl+'ar/about'" target="_blank"><b-icon icon="twitter" scale="1" shift-v="1" aria-hidden="true"></b-icon></b-nav-item>
     
            </b-nav>
      </b-col>
    </b-row>


</b-container>

    </div>
</template>

<script>
import axios from 'axios'
//import PostsSkeleton from '@/components/skeletons/PostsSkeleton.vue'
  export default {
      name:'Competitions',
    data() {
      return {
        showContent: false,
        path:this.$store.state.backendUrl+"api/ar/about",
        post:[],
        meta:{
           title:"",
           description:"",
           favicon :""
        },
      }
    },
    components:{
     // PostsSkeleton
    }
    ,
    methods: {
  
           getCreateData(){
           this.showContent =false;
           axios.get(this.path, {params:this.form,
            headers: {
          // 'Authorization': 'Bearer ' + this.$store.state.token,
            "Content-Type": "application/json",
            }
            })
            .then((response) => {
                console.log(response);
                this.post = response.data.success.post;
               this.showContent =true;
         
            })
            .catch( (error) => {
                console.log(error);
                this.ShowError=true;
                this.errorMgs = error;
                
            });
           
      } ,
 
    },
     metaInfo() {
      return {
      title: this.meta.title ,
      meta:[{ name: 'description', content: this.meta.description }] ,
      link:[{ rel: 'icon', href: this.meta.favicon }] 
  
    }
     },
    mounted(){
     this.getCreateData();
     this.meta.title = this.$t('About') +' | '+this.$store.state.info.name;
     this.meta.description=this.$store.state.info.short_decription;
     this.meta.favicon =this.$store.state.backendUrl+'/'+this.$store.state.info.favicon ;
    }
  }
</script>